// Explore Degree Css
.itemBox {
  background-color: #b5e0ff;
  color: #111010;
  opacity: 0.7;
  transition: background-color 0s, color 0s;
  border-radius: 10px;
}
.itemBox:hover {
  background-color: #45adf8;
  color: #000000;
}
.boxbgcolor {
  background-color: #f4f6f8;
}
.addUniversity:hover {
  background-color: #fbfbfb !important;
}
.dashboard:hover {
  background-color: #fbfbfb !important;
}
.DownloadExcel:hover {
  background: #f4f6f8 !important;
}

// table scrollbar style
.scrollBarCss::-webkit-scrollbar {
  height: 8px !important;
  width: 20 !important;
}
.scrollBarCss::-webkit-scrollbar-track {
  background-color: #90caf9 !important;
}
.scrollBarCss::-webkit-scrollbar-thumb {
  background-color: #0288d1 !important;
  border-radius: 2px !important;
}
