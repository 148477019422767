* {
  font-family: "'Nunito Sans', sans-serif";
}

body {
  margin: 0;
  font-family: "'Nunito Sans', sans-serif", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body::-webkit-scrollbar {
  width: 12px; /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background-color: #90caf9 !important; /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: #3199d1 !important;
  border-radius: 20px !important;
}
