.bgcolor {
  background-color: cadetblue;
  border: 1px solid rgb(0, 6, 6);
}
.border {
  border-bottom: "1 px solid black";
  border-right: "1 px solid black";
}
.onhover:hover{
  background-color: #edf4f4;
}
