.formtext {
  color: hsl(226, 85%, 26%);
  font-size: 25px;
}
.formtext1 {
  color: hsl(226, 85%, 26%);
  font-size: 20px;
}
.MuiInputBase-input {
  background: rgb(242, 246, 248);
}
.MuiInputBase-input :hover {
  border: black;
}
.MuiTextField-root {
  background: rgb(235, 241, 243);
}
.MuiTextField-root :hover {
  border: black;
}
.circle-img {
  width: 130px;
  border-radius: 50rem;
}
